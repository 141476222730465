import React from "react";
import pic from "../assets/4.jpg";

const Contact = () => {
  return (
    <div className="mt-[80px] lg:px-20 px-10 lg:py-20 py-10">
      <div className="lg:grid lg:grid-cols-2 justify-center gap-8 items-center flex flex-col ">
        <img
          src={pic}
          className="w-full h-[200px] sm:h-[400px] mx-auto"
          alt=""
        />
        <div className="flex mx-auto w-full">
          <form
            action="https://docs.google.com/forms/d/e/1FAIpQLSfFIvFz1esjteiYywiAzo5idZMXpenxsNquX0_HjtL0x9NiZA/formResponse"
            method="POST"
            className="mx-auto grid gap-4 lg:w-2/3 w-full">
            <input
              type="text"
              name="entry.1459817151"
              placeholder="Name"
              className="input"
            />
            <input
              type="text"
              name="entry.1520550356"
              placeholder="Email"
              className="input"
            />
            <input
              type="text"
              name="entry.1501945934"
              placeholder="Phone Number"
              className="input"
            />
            <textarea
              className="input"
              name="entry.1070775524"
              placeholder="Message"
              cols="30"
              rows="6"></textarea>
            <button name="fbzx" id="submit-button" className="yes font-medium">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;

// https://docs.google.com/forms/d/e/1FAIpQLSfFIvFz1esjteiYywiAzo5idZMXpenxsNquX0_HjtL0x9NiZA/viewform?usp=sf_link
// https://docs.google.com/forms/d/e/1FAIpQLSfFIvFz1esjteiYywiAzo5idZMXpenxsNquX0_HjtL0x9NiZA/formResponse
