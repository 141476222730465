import { useEffect } from "react";
import pic1 from "../assets/5.jpg";
import pic2 from "../assets/3.jpg";
import pic3 from "../assets/6.jpg";
import pic4 from "../assets/2.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

const Service = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className=" lg:px-20 px-10 lg:py-20 py-10">
      <div>
        <Card1
          pic={pic1}
          title="Website Design"
          desc="Our team of experienced designers will work with you to create a
          visually stunning and user-friendly website that accurately represents
          your brand and effectively communicates your message. We specialize in
          creating custom designs tailored to your specific needs and goals.
          Whether you need a simple brochure website or a complex e-commerce
          platform, we have the skills and expertise to deliver a high-quality
          product that exceeds your expectations."
        />
        <Card2
          pic={pic2}
          title="Web Application Development"
          desc="We offer a wide range of web application development services, from custom software development to CMS and CRM implementation. Our team of experienced developers will work with you to understand your business needs and create a solution that is tailored to your specific requirements. We specialize in creating scalable, secure, and robust web applications that will help your business grow and thrive."
        />
        <Card1
          pic={pic3}
          title="Static Websites"
          desc="Static websites are simple, easy-to-use websites that are perfect for small businesses, personal blogs, and portfolios. They are built using basic HTML, CSS, and JavaScript and do not require any kind of backend programming. Because they are simple and easy to maintain, static websites are a cost-effective solution for those looking to establish an online presence without breaking the bank."
        />
        <Card2
          pic={pic4}
          title="Dynamic Websites"
          desc="Dynamic websites are more complex and interactive than static websites, they are built using server-side scripting languages such as Django, Nodejs. They are able to display different content based on user interactions, user preferences and are ideal for e-commerce, social networking, and other interactive applications. They allow for easy updates and maintenance and are a great solution for businesses that need a more robust and dynamic online presence."
        />
      </div>
    </div>
  );
};

export default Service;

function Card1({ pic, title, desc }) {
  return (
    <div className="lg:grid lg:grid-cols-2 flex flex-col justify-center items-center mt-20 ">
      <img
        src={pic}
        className="w-full h-[250px] sm:h-[350px]"
        alt=""
        data-aos="fade-right"
        data-aos-duration="1500"
      />
      <div
        className="lg:w-2/3 w-full mx-auto mt-4"
        data-aos="fade-left"
        data-aos-duration="1500">
        <li className="font-bold text-xl">{title}</li>
        <li className="mt-2">{desc}</li>
      </div>
    </div>
  );
}

function Card2({ pic, title, desc }) {
  return (
    <div className="lg:grid lg:grid-cols-2 flex flex-col-reverse justify-center items-center mt-20">
      <div
        className="lg:w-2/3 w-full  mt-4"
        data-aos="fade-right"
        data-aos-duration="1500">
        <li className="font-bold text-xl">{title}</li>
        <li className="mt-2">{desc}</li>
      </div>
      <img
        src={pic}
        className="w-full h-[250px] sm:h-[350px]"
        alt=""
        data-aos="fade-left"
        data-aos-duration="1500"
      />
    </div>
  );
}
