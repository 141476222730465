import { useEffect } from "react";
import pic from "../assets/1.jpg";
import pic1 from "../assets/5.jpg";
import pic2 from "../assets/3.jpg";
import pic3 from "../assets/6.jpg";
import pic4 from "../assets/2.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const Home = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="mt-[80px] ">
      {/* SECTION 1 */}
      <div
        className="lg:grid lg:grid-cols-2 justify-center items-center flex flex-col-reverse "
        data-aos="fade-right"
        data-aos-duration="1500">
        <div className="lg:mt-0 mt-8 lg:pl-20 px-8 ">
          <h1 className=" w-full text-4xl font-bold">
            Make your website stand out from the crowd with msone.
          </h1>
          <p className="mt-4">
            Our team of experienced designers will work with you to create a
            visually stunning and user-friendly website that accurately
            represents your brand and effectively
          </p>
          <Link to="/service">
            <button className="bg-black text-white rounded mt-6 px-8 py-2 hover:scale-105">
              Explore More
            </button>
          </Link>
        </div>
        <img
          data-aos="fade-right"
          data-aos-duration="1500"
          src={pic1}
          className="w-full h-[450px] mx-auto banner-img "
          alt=""
        />
      </div>

      {/* SECTION 2 */}
      <div className="mt-20 py-8 lg:px-20 px-8">
        <li className="lg:text-3xl text-2xl font-bold underline text-center uppercase">
          we provide services
        </li>
        <div className="lg:grid lg:grid-cols-2 justify-items-center  flex flex-col">
          {/* <Pictute pic={pic1} title="website design" />
          <Pictute pic={pic2} title="Web Application Development" />
          <Pictute pic={pic3} title="Static Websites" />
          <Pictute pic={pic4} title="Dynamic Websites" /> */}
          <Card
            pic={pic1}
            title="website design"
            desc="Your website serves as the virtual representation of your online business and must possess an appealing and informative quality to captivate a significant volume of visitors."
          />
          <Card
            pic={pic2}
            title="Web Application Development"
            desc="Crafting Dynamic Digital Solutions
Unlocking Possibilities Through Expert Web Application Development"
          />
          {/* <Card pic={pic3} title="Static Websites" />
          <Card pic={pic4} title="Dynamic Websites" /> */}
        </div>
      </div>
    </div>
  );
};

export default Home;

function Pictute({ pic, title }) {
  return (
    <div
      className="w-full lg:w-4/5 h-[300px] bg-cover mt-20"
      style={{ backgroundImage: "url(" + pic + ")" }}
      data-aos="fade-up"
      data-aos-duration="1500">
      <div className="flex justify-center w-full h-full items-center bg-black50">
        <button className="bg-white p-4 rounded text-xl font-bold uppercase mx-8">
          {title}
        </button>
      </div>
    </div>
  );
}

function Card({ pic, title, desc }) {
  return (
    <div className="w-full lg:w-4/5 rounded-lg card-shadow  mt-10">
      <div
        className=" h-[200px]  h bg-cover bg-center rounded-t-lg"
        style={{ backgroundImage: "url(" + pic + ")" }}></div>
      <li className="mt-3 px-4 font-semibold text-xl">{title}</li>
      <li className="mt-2 px-4">{desc}</li>
      <Link to="/service">
        <button className="bg-white  rounded text font-bold my-6 border px-4 py-2 mx-4">
          Learn More &rarr;
        </button>
      </Link>
    </div>
  );
}
