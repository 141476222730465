import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-black  text-white lg:px-20 px-8 py-20 lg:grid lg:grid-cols-3 justify-items-center flex flex-col">
      <div>
        <button className="text-2xl font-bold uppercase">msone</button>
      </div>
      <div className="flex flex-col mt-8 lg:mt-0">
        <Link to="/">
          <button className="uppercase text-sm font-medium">Home</button>
        </Link>
        <Link to="/service">
          <button className="uppercase text-sm font-medium">Services</button>
        </Link>
        <Link to="/contact">
          <button className="uppercase text-sm font-medium">Contact us</button>
        </Link>
      </div>
      <div className="">
        <li className="uppercase  font-medium mb-2">Contact us</li>
        <li className="text-sm">Email: admin@msone.in</li>
        <li className="text-sm mt-2">Phone: +91 96666 18481</li>
      </div>
    </div>
  );
};

export default Footer;
