import React, { useState, useEffect, useCallback, useRef } from "react";
import { useMatch, useNavigate, useLocation } from "react-router-dom";

const Header = () => {
  const [show, setShow] = useState(false);

  const location = useLocation();

  // PREVENT FROM SCROLL / GO TO NEXT PAGE START
  const prevLocation = useRef(location);
  const handleLocationChange = useCallback(() => {
    if (prevLocation.current.pathname !== location.pathname) {
      window.scrollTo(0, 0);
    }
    prevLocation.current = location;
  }, [location]);

  useEffect(() => {
    handleLocationChange();
  }, [handleLocationChange]);
  // PREVENT FROM SCROLL / GO TO NEXT PAGE END

  // SCROLL TO HIDE OR SHOW HEADER START
  // const [header, setHeader] = useState(false);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (location.pathname === "/") {
  //       if (window.scrollY > 100) {
  //         setHeader(true);
  //       } else {
  //         setHeader(false);
  //       }
  //     } else setHeader(true);

  //     if (location.pathname !== "/") {
  //       setHeader(true);
  //     }
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [location.pathname]);
  // SCROLL TO HIDE OR SHOW HEADER END

  return (
    <div
      className={`${
        // header?
        "fixed top-0 left-0 w-screen h-20 bg-black text-white z-10"
        // : "hidden"
      }`}>
      <div className="flex justify-between items-center w-full h-full lg:px-20 px-8">
        <NavLink to="/">
          <button className="text-2xl font-bold uppercase text-white">
            msone
          </button>
        </NavLink>
        <button
          className="flex lg:hidden text-4xl"
          onClick={() => setShow(!show)}>
          &#8801;
        </button>
        <div className="lg:flex gap-4 hidden">
          <NavLink to="/">
            <button className={`uppercase text-sm font-medium`}>Home</button>
          </NavLink>
          <NavLink to="/service">
            <button className={`uppercase text-sm font-medium`}>
              Services
            </button>
          </NavLink>
          <NavLink to="/contact">
            <button className={`uppercase text-sm font-medium`}>
              Contact us
            </button>
          </NavLink>
        </div>
      </div>

      {show && <Menu show={setShow} />}
    </div>
  );
};

export default Header;

const NavLink = ({ to, children, header }) => {
  const navigate = useNavigate();
  const match = useMatch({ path: to, exact: true });
  // const className = match ? 'active' : 'inactive';
  // const className = match && header ? "both" : match ? "one" : "none";

  return (
    <a
      href={to}
      // className={className}
      style={{ color: match ? "#ff9900" : "#fff" }}
      onClick={(event) => {
        event.preventDefault();
        navigate(to);
      }}>
      {children}
    </a>
  );
};

const NavLink2 = ({ to, children, header }) => {
  const navigate = useNavigate();
  const match = useMatch({ path: to, exact: true });
  // const className = match ? 'active' : 'inactive';
  // const className = match && header ? "both" : match ? "one" : "none";

  return (
    <a
      href={to}
      // className={className}
      style={{ color: match ? "#ff9900" : "#000" }}
      onClick={(event) => {
        // event.preventDefault();
        navigate(to);
      }}>
      {children}
    </a>
  );
};

function Menu({ show }) {
  return (
    <>
      <div
        className="bg-black50 fixed w-screen h-screen"
        onClick={() => show(false)}></div>
      <div className="bg-white fixed w-[70%] h-full">
        <div className="grid gap-6 px-10 pt-10">
          <NavLink2 to="/">
            <button className={`uppercase font-medium`}>Home</button>
          </NavLink2>
          <NavLink2 to="/service">
            <button className={`uppercase font-medium`}>Services</button>
          </NavLink2>
          <NavLink2 to="/contact">
            <button className={`uppercase font-medium`}>Contact us</button>
          </NavLink2>
        </div>
      </div>
    </>
  );
}
